

/*
//Rage Template
//Designerd by: http://bootstrapthemes.co
*/

/*==========================================
 01.  PREDIFINEED (FROM 1 TO 780 )
 02.  PRELOADER - LINE NO 786
 03.  NAVIGATION - LINE NO 930
 04.  HOME - LINE NO 1150
 05.  THU FEATURE - LINE NO 1280
 06.  THU FLIDE - LINE NO 1355
 07.  THU SERVICE - LINE NO 1385
 08.  PORTFOLIO - LINE NO 1444
 09.  THU SOLUTION - LINE NO 1620
 10.  THU TEAM - LINE NO 1658
 11.  THU CLIENTS FEEDBACK - LINE NO 1730
 12. THU BLOG - LINE NO 1848
 13. THU SUBCRIBS - LINE NO 1935
 14. FOOTER - LINE NO 2020
==========================================*/


/* ==========================================================================
   Author's custom styles
   ========================================================================== */
html,
body {
    background-color: #fff;
    font-size: 14px;
    color: #797979;
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    font-weight: 400;
    width:100%;
    font-family: 'Raleway', sans-serif;
}

/*------------------------------------------------------------------*/
/*   IE10 in Windows 8 and Windows Phone 8 Bug fix
/*-----------------------------------------------------------------*/

@-webkit-viewport { width: device-width; }
@-moz-viewport { width: device-width; }
@-ms-viewport { width: device-width; }
@-o-viewport { width: device-width; }
@viewport { width: device-width; }

/*
    Page Content
------------------------------------------*/
/*
.page-content, 
section { overflow: hidden; }*/

/*
    On Scroll Animations 
------------------------------------------*/

/*.animated { visibility: hidden; }
.visible { visibility: visible; }*/

/*
    Spacing & Alignment 
------------------------------------------*/

.roomy-100 {
    padding-top: 100px;
    padding-bottom: 100px;
}

.roomy-80 {
    padding-top: 100px;
    padding-bottom: 80px;
}

.roomy-70 {
    padding-top: 100px;
    padding-bottom: 70px;
}

.roomy-60 {
    padding-top: 100px;
    padding-bottom: 60px;
}

.roomy-50 {
    padding-top: 50px;
    padding-bottom: 50px;
}

.roomy-40 {
    padding-top: 40px;
    padding-bottom: 40px;
}


/*text color*/

.text-muted{}

.text-primary{color: #ff6863 !important;;}
.text-finence{color:#13A0B2 !important;;}
.text-mega{color:#ff6863 !important;;}
.text-white{color:#fff !important;;}
.text-black{color:#000 !important;}

.text-warning{}
.text-danger{}

.disabled{
    cursor: not-allowed;
}

/*bacground color*/
.lightbg {
    background-color: #f2f2f2;
}
.bg-primary{background-color: #1E8BC3;}
.bg-finence{background-color:#13A0B2;}
.bg-mega{background-color:#6a6b6c;}
.bg-grey{background-color: #f5f5f5;}
.bg-black{background-color: #212121;}
.bg-white{background-color: #fff;}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    -user-select: none;
    -webkit-user-drag: none;
    -user-drag: none;
}
a,
a:hover,
a:active,
a:focus {
    text-decoration: none;
    outline: none;
}
a,
button,
a span {
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
}
a {
    -webkit-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
}

.btn:focus,
.btn:active {
    outline: inherit;
    background-color: transparent;
}
*,
*:before,
*:after {
    box-sizing: border-box;
    /*    direction: rtl;*/
}

p {
    margin: 0 0 15px;
}

.clear {
    clear: both;
}

img {
    max-width: 100%;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    outline: none;
    /*color:#fff;*/
}

input:-webkit-autofill, textarea:-webkit-autofill, select:-webkit-autofill {
    background-color: transparent;
    background-image: none;
    /*color:#fff;*/
}

.form-control{
    border-radius: 0px;
    border:1px solid #ccc;
    box-shadow: inset 0 0px 0px rgba(0,0,0,.075);
    width: 100%;
    height: 50px;
    /*color:#fff;*/
}

.form-control:focus {
    border-color: #bcefeb;
    outline: 0;
    box-shadow: inset 0 0px 0px rgba(0,0,0,.075),0 0 0px rgba(102,175,233,.6);
    /*color:#fff;*/
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    color:#1a1a1a;
    margin-bottom: 10px;
}

h1 {
    font-size: 3rem;
    line-height: 4rem;
}
h2 {
    font-size: 2.5rem;
    line-height: 3rem;
}
h3 {
    font-size: 2.1rem;
    line-height: 2.5rem;
}
h4 {
    font-size: 1.6rem;
    line-height: 2.2rem;
}
h5 {
    font-size: 1.3rem;
}
h6 {
    font-size: 1rem;
}



h1 span,
h2 span,
h3 span,
h4 span,
h5 span,
p span{
    color:#ff6863;
}

p {
    line-height: 1.5rem;
}
p:last-child {
    margin-bottom: 0px;
}
/*colors*/
.w100 {
    font-weight: 100;
}

.w200 {
    font-weight: 200;
}

.w300 {
    font-weight: 300;
}

.w400 {
    font-weight: 400;
}

.w500 {
    font-weight: 500;
}

.w600 {
    font-weight: 600;
}

.w700 {
    font-weight: 700;
}

.w800 {
    font-weight: 800;
}


/*
Section
*/

.parallax-section {
    max-width: 100%;
    color: #ffffff;
}

ul{
    margin:0;
    padding:0;
    list-style:none;
}

/*all overlay*/
.overlay{
    background-color: rgba(51,51,51,0.30);
    position: absolute;
    left:0;
    top:0;
    width:100%;
    height: 100%;
}



.no-padding {
    padding: 0 !important;
    margin:0 !important;
}
.left-no-padding {
    padding-left: 0 !important;
}
.right-no-padding {
    padding-right: 0 !important;
}
.fluid-blocks-col {
    padding: 70px 40px 0 80px;
}
.fluid-blocks-col-right {
    padding: 70px 80px 0 40px;
}


/*
Separator
*/
.separator_left{
    width:85px;
    height:2px;
    margin:20px 0px;
    background: #ff6863;
}
.separator_auto{
    width:85px;
    height:2px;
    margin:20px auto;
    background: #ff6863;
}
.separator_small{
    width:30px;
    height:2px;
    margin:20px 0px;
    background: #ff6863;
}

/*
Button
*/
.btn{
    -webkit-transition: all 0.6s;
    transition: all 0.6s;
    text-transform: uppercase;
    font-size:14px;
    font-weight: 600;
    border-radius: 0;
    border:1px solid;
}

.btn:hover{
    color:#fff;
    border:1px solid;
}

.btn-default{
    border: 1px solid;
    border-color:#f5f5f5;
    background-color: transparent;
    color:#fff;
    padding:1rem 2rem;
    border-radius: 0;
}
.btn-default:hover{
    border-color:#ff6863;
    background-color: #ff6863;
}

.btn-primary{
    color: #fff;
    background-color: #ff6863;
    border:1px solid;
    border-color: #ff6863;
    padding: 1rem 2rem;
}
.btn-primary:hover{
    background-color: #eee;
    border-color: #eee;
    color:#ff6863;
}

.btn-success{}
.btn-info{}
.btn-warning{}
.btn-danger{}
.btn-link{}



/*
socail icon
--------------------------------*/
/*a i.share{
    color:#1E8BC3;
    -webkit-transition: all .6s;
    transition: all .6s;
}*/


/*
Extra height css
*/
.m-0{
    margin:0 !important;
}
.m-top-0{
    margin-top:0;
}
.m-r-0{
    margin-right: 0;
}
.m-l-0{
    margin-left: 0;
}
.m-b-0{
    margin-bottom: 0;
}

.m-r-15{
    margin-right: 15px;
}
.m-l-15{
    margin-left: 15px; 
}
.m-top-10 {
    margin-top: 10px;
}
.m-top-20 {
    margin-top: 20px;
}
.m-top-30 {
    margin-top: 30px;
}
.m-top-40 {
    margin-top: 40px;
}
.m-top-50 {
    margin-top: 50px;
}
.m-top-60 {
    margin-top: 60px;
}
.m-top-70 {
    margin-top: 70px;
}
.m-top-80 {
    margin-top: 80px;
}
.m-top-90 {
    margin-top: 90px;
}
.m-top-100 {
    margin-top: 100px;
}
.m-top-110 {
    margin-top: 110px;
}
.m-top-120 {
    margin-top: 120px;
}

.m-t-b-80 {
    margin-top: 80px;
    margin-bottom: 80px;
}
.m-t-b-120 {
    margin-top: 120px;
    margin-bottom: 120px;
}

.m-bottom-10 {
    margin-top: 10px;
}

.m-bottom-20 {
    margin-bottom: 20px;
}
.m-bottom-30 {
    margin-bottom: 30px;
}

.m-bottom-40 {
    margin-bottom: 40px;
}

.m-bottom-60 {
    margin-bottom: 60px;
}

.m-bottom-70 {
    margin-bottom: 70px;
}

.m-bottom-80 {
    margin-bottom: 80px;
}

.m-bottom-90 {
    margin-bottom: 90px;
}

.m-bottom-100 {
    margin-bottom: 100px;
}

.m-bottom-110 {
    margin-bottom: 110px;
}

.m-bottom-120 {
    margin-bottom: 120px;
}

.m_t__b{
    margin: 14rem 0;
}
.p_t__b{
    padding: 6rem 0;
}

p-0{
    padding:0 !important;
}

.p-l-15{
    padding-left: 15px;
}

.p-r-15{
    padding-right: 15px;
}

.p-top-10 {
    padding-top: 10px;
}
.p-top-20 {
    padding-top: 1.33rem;
}

.p-bottom-20 {
    padding-bottom: 1.33rem;
}

.p-top-30 {
    padding-top: 1.875rem;
}

.p-bottom-30 {
    padding-bottom: 1.875rem;
}

.p-top-40 {
    padding-top: 2.66rem;
}

.p-bottom-40 {
    padding-bottom: 2.66rem;
}

.p-top-60 {
    padding-top: 5rem;
}

.p-bottom-60 {
    padding-bottom: 5rem;
}

.p-top-80 {
    padding-top: 5.714rem;
}

.p-bottom-80 {
    padding-bottom: 5.714rem;
}

.p-top-90 {
    padding-top: 6.429rem;
}

.p-bottom-90 {
    padding-bottom: 6.429rem;
}
.p-top-100 {
    padding-top: 100px;
}

.p-bottom-100 {
    padding-bottom: 100px;
}
.p-top-110 {
    padding-top: 110px;
}

.p-bottom-110 {
    padding-bottom: 110px;
}

.p-bottom-0 {
    padding-bottom: 0;
}

.p_l_r{
    padding-left: 5.714rem;
    padding-right: 5.714rem;
}

/*padding*/
.padding-twenty {
    padding: 10px 0;
}
.padding-fourty {
    padding: 20px 0;
}
.padding-sixty {
    padding: 30px 0;
}
.padding-eighty {
    padding: 40px 0;
}

/*border*/
.no-border-top{
    border-top:0px solid transparent !important;
}
.no-border-rigth{
    border-right:0px solid transparent !important;
}

/*for placeholder changes*/

input[type="email"].form-control::-webkit-input-placeholder {
    color: #fff;
    background-color: transparent;
}

input::-webkit-input-placeholder {
    color: #cdcdcd;
    font-style:italic;
}

input::-moz-placeholder {
    color: #cdcdcd;
    font-style:italic;
}

input:-ms-input-placeholder {
    color: #cdcdcd;
    font-style:italic;
}

input::placeholder {
    color: #cdcdcd;
    font-style:italic;
}
input::-webkit-input-placeholder {
    color: #cdcdcd;
    font-style:italic;
}
input::-moz-placeholder {
    color: #cdcdcd;
    font-style:italic;
}
input::-ms-input-placeholder {
    color: #cdcdcd;
    font-style:italic;
}
textarea::-webkit-input-placeholder{
    color: #cdcdcd;
    font-style:italic;
    padding: 5px;
}
textarea::-moz-input-placeholder{
    color: #cdcdcd;
    font-style:italic;
    padding: 5px;
}
textarea::-ms-input-placeholder{
    color: #cdcdcd;
    font-style:italic;
    padding: 5px;
}

/*for image bg*/
/*.img-bg{
  background: url(../images/home-msg.jpg) no-repeat center top fixed;
  background-size
  -moz-background-size: cover;
  -webkit-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100%;
  overflow: hidden;
}*/


.transform_scal_rotate:hover img{ 
    -webkit-transform: scale(1.1) rotate(4deg); 
    transform: scale(1.1) rotate(4deg); 
}

.culmn{
    width:100%;
    overflow: hidden;
}
/*heading title all area*/
.head_title{
    padding-bottom: 7.143rem;
    overflow: hidden;
}
.head_title h2{
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
    font-size:2.286rem;
    color:#1a1a1a;
}
.head_title p{
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    font-size:1rem;
    color:#4a4a4a;
}

/*End off pridifain css*/


/*Preloader css*/

#loading{
    background-color: #d14149;
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    margin-top: 0px;
    top: 0px;
    left:0;
    z-index: 9999;
}
#loading-center{
    width: 100%;
    height: 100%;
    position: relative;
}
#loading-center-absolute {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 150px;
    width: 150px;
    margin-top: -75px;
    margin-left: -75px;	
    -ms-transform: rotate(45deg); 
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg); 

}
.object{
    width: 20px;
    height: 20px;
    background-color: #FFF;
    position: absolute;
    left: 65px;
    top: 65px;
    -moz-border-radius: 50% 50% 50% 50%;
    -webkit-border-radius: 50% 50% 50% 50%;
    border-radius: 50% 50% 50% 50%;
}
.object:nth-child(2n+0) {
    margin-right: 0px;

}
#object_one {
    -webkit-animation: object_one 2s infinite;
    animation: object_one 2s infinite;
    -webkit-animation-delay: 0.2s; 
    animation-delay: 0.2s; 
}
#object_two {
    -webkit-animation: object_two 2s infinite;
    animation: object_two 2s infinite;
    -webkit-animation-delay: 0.3s; 
    animation-delay: 0.3s; 
}
#object_three {
    -webkit-animation: object_three 2s infinite;
    animation: object_three 2s infinite;
    -webkit-animation-delay: 0.4s; 
    animation-delay: 0.4s; 
}
#object_four {
    -webkit-animation: object_four 2s infinite;
    animation: object_four 2s infinite;
    -webkit-animation-delay: 0.5s; 
    animation-delay: 0.5s; 
}
#object_five {
    -webkit-animation: object_five 2s infinite;
    animation: object_five 2s infinite;
    -webkit-animation-delay: 0.6s; 
    animation-delay: 0.6s; 
}
#object_six {
    -webkit-animation: object_six 2s infinite;
    animation: object_six 2s infinite;
    -webkit-animation-delay: 0.7s; 
    animation-delay: 0.7s; 
}
#object_seven {
    -webkit-animation: object_seven 2s infinite;
    animation: object_seven 2s infinite;
    -webkit-animation-delay: 0.8s; 
    animation-delay: 0.8s; 
}
#object_eight {
    -webkit-animation: object_eight 2s infinite;
    animation: object_eight 2s infinite;
    -webkit-animation-delay: 0.9s; 
    animation-delay: 0.9s; 
}

#object_big{

    position: absolute;
    width: 50px;
    height: 50px;
    left: 50px;
    top: 50px;
    -webkit-animation: object_big 2s infinite;
    animation: object_big 2s infinite;
    -webkit-animation-delay: 0.5s; 
    animation-delay: 0.5s; 
}	


@-webkit-keyframes object_big {
    50% { -webkit-transform: scale(0.5); }

}

@keyframes object_big {
    50% { 
        transform: scale(0.5);
        -webkit-transform: scale(0.5);
    } 

}

@-webkit-keyframes object_one {
    50% { -webkit-transform: translate(-65px,-65px)  ; }

}

@keyframes object_one {
    50% { 
        transform: translate(-65px,-65px) ;
        -webkit-transform: translate(-65px,-65px) ;
    } 

}


@-webkit-keyframes object_two {
    50% { -webkit-transform: translate(0,-65px) ; }
}

@keyframes object_two {
    50% { 
        transform: translate(0,-65px) ; 
        -webkit-transform: translate(0,-65px) ; 
    } 

}

@-webkit-keyframes object_three {
    50% { -webkit-transform: translate(65px,-65px) ; }
}

@keyframes object_three {
    50% { 
        transform: translate(65px,-65px) ;
        -webkit-transform: translate(65px,-65px) ;
    } 
}

@-webkit-keyframes object_four {

    50% { -webkit-transform: translate(65px,0) ; }

}

@keyframes object_four {
    50% { 
        transform: translate(65px,0) ;
        -webkit-transform: translate(65px,0) ;
    } 

}

@-webkit-keyframes object_five {

    50% { -webkit-transform: translate(65px,65px) ; }

}

@keyframes object_five {
    50% { 
        transform: translate(65px,65px) ;
        -webkit-transform: translate(65px,65px) ;
    } 

}

@-webkit-keyframes object_six {

    50% { -webkit-transform: translate(0,65px) ; }

}

@keyframes object_six {
    50% { 
        transform:  translate(0,65px) ;
        -webkit-transform:  translate(0,65px) ;
    } 

}

@-webkit-keyframes object_seven {

    50% { -webkit-transform: translate(-65px,65px) ; }

}

@keyframes object_seven {
    50% { 
        transform: translate(-65px,65px) ;
        -webkit-transform: translate(-65px,65px) ;
    } 

}

@-webkit-keyframes object_eight {

    50% { -webkit-transform: translate(-65px,0) ; }

}

@keyframes object_eight {
    50% { 
        transform: translate(-65px,0) ;
        -webkit-transform: translate(-65px,0) ;
    } 

}

/*End off Preloader*/


/*
Nav Menu style
==================*/

.cart-list li h6 a{
    color:#000;
}
.btn-cart{
    background-color: #ff6863;
    padding: 2px 10px;
    color:#fff !important;
    border:0px solid;
    display: inline-block;
}
.btn-cart:hover{
    border:0px solid;
    background-color: #ff7854 !important;
}


/*
Home Section style
==================*/
.home{
    background: url(../images/cabinet_alicja.jpg) no-repeat bottom center;
    background-size: cover;
    position: relative;
}
.main_home{
    overflow: hidden;
    padding-top: 300px;
    padding-bottom: 230px;
    position: relative;
    z-index: 1;
}
.home_text h1{
    font-family: 'Raleway', sans-serif;
    font-size:4.286rem;
    font-weight: 700;
    line-height: 6.5rem;
}



/*
About section style
====================*/
.main_about{
    display: table;
    width:100%;
}

.about_bottom_content{}
.about_bottom_content .about_bottom_item{}
.ab_head{
    width:100%;
    display: table;
}
.ab_head .ab_head_icon{
    float: left;
    margin-right: 20px;
}
.ab_head .ab_head_icon .icofont,
.ab_head .ab_head_icon .icon{
    width:60px;
    height: 60px;
    line-height: 60px;
    font-size:1.5rem;
    background-color: #ff6863;
    color:#fff;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 6px rgba(255, 104, 99, 0.4);

}

.about ul {
    list-style: square;
}


/*
FAQ Page
--------*/

.faq_main_content h6{
    margin-bottom: 0rem;
    border: 1px solid;
    border-color:#eee;
    position: relative;
}

.faq_main_content h6 + div {
    height: 0px;
    padding: 0px;
    overflow: hidden;
    display: block!important;
    -webkit-transform: translateZ(0);
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
    margin-bottom: 10px;
    border:0px solid #cdcdcd;
}

.faq_main_content h6 + div.open {
    height: 133px;
    border:1px solid;
    border-top:0;
    border-color: #eee;
}

.faq_main_content h6 a{
    display: block;
    color: #6a6a6a;
    text-decoration: none;
    font-weight: normal;
    overflow: hidden;
}

.faq_main_content h6 span{
    font-weight: 600;
}

.faq_main_content h6 a i{
    width: 50px;
    height: 50px;
    line-height: 50px;
    background-color: #eeeeee;
    text-align: center;
    color:#444444;
    margin-right: 15px;
    font-size:1.5rem;
}

.faq_main_content h6.open a{
    color:#444;
}

.faq_main_content h6.open a i{
    color:#fff;
    background-color: #444444;
}


.faq_main_content h6.open a i{
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}

.faq_main_content .content {
    padding: 25px;
    color:#4a4a4a;
}


/*
Featured section style
========================*/
.main_featured .head_title{
    width:70%;
    margin:0 auto;
}

.slick-center .featured_img{
    position: relative;
}
.slick-center .featured_img:before{
    content:"+";
    width:50px;
    height: 50px;
    line-height: 50px;
    position: absolute;
    left:43.5%;
    right:43.5%;
    top:35%;
    color: #000;
    background-color: #fff;
    border-radius: 50%;
    font-size:3rem;
    z-index: 2;
    cursor: pointer;
}
.slick-center .featured_img a{
    position: absolute;
    left:43.5%;
    right:43.5%;
    top:35%;
    display: block;
    width:50px;
    height: 50px;
    z-index: 3;
}
.featured_img a{
    display: none;
}
.featured_slider{
    width: 100%;  
    height:250px;
    margin: 20px auto;    
    text-align: center;
}
.featured_img img{
    width:100%;
    height: 100%;
}
.featured_slider div{
    margin-right: 5px;
}
.slick-slide{
    opacity: .6;
}
.slick-center{
    display: block;
    max-width: 10% !important;
    max-height:20% !important;
    opacity: 1;


}


/*
service section style
========================*/
.main_featured,
.main_service{
    display: table;
    overflow: hidden;
    width:100%;
}


.service_item{
    width:100%;
    overflow: hidden;
    margin-bottom: 30px;
}
.service_item i.icofont,
.service_item i.fa,
.service_item span.icon{
    font-size:3rem;
}
.service_item img{
    max-width: 300px;
    box-shadow: 5px 5px 10px;
}
.service_item h6{}
.service_item p{}


/*
Portfolio section style
========================*/

.main_portfolio{
    width:100%;
    overflow: hidden;
}
.portfolio_item{
    position: relative;
    overflow: hidden;
}
.portfolio_item:hover img{
    -webkit-transform: scale(1.1) rotate(2deg);
    -moz-transform: scale(1.1) rotate(2deg);
    -o-transform: scale(1.1) rotate(2deg);
    -ms-transform: scale(1.1) rotate(2deg);
    transform: scale(1.1) rotate(2deg);
}
.portfolio_item img{
    width:100%;
    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -ms-transition: all 0.6s;
    transition: all 0.6s;
}
.portfolio_hover{
    position: absolute;
    left:0%;
    right:0%;
    top:0%;
    width:100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.30); 
    padding-top: 12%;
    opacity: 0;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
}
.portfolio_item:hover .portfolio_hover{
    opacity: 1;
}
.portfolio_item2 .portfolio_hover{ 
    padding-top: 25%;
}
.portfolio_item3 .portfolio_hover{ 
    padding-top: 65%;
}

.home_btns ,
.impress_btns ,
.portfolio_hover_icon{
    margin-left: -10px;
}
.subscribe_btns button.btn,
.home_btns a,
.impress_btns a,
.portfolio_hover_icon a{
    margin-left: 10px;
}

.portfolio_hover_icon a i{
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    border:1px solid;
    border-color:#fff;
    display: inline-block;
    color:#fff;
    border-radius: 50%;
}




/*
Skill Section style
=======================*/

/* Skill bar*/

.teamskillbar {
    position:relative;
    display:block;
    margin-bottom:15px;
    width:100%;
    background: #f2f2f2;
    height:10px;
    -webkit-transition:0.4s linear;
    transition:0.4s linear;
    -webkit-transition-property:width, background-color;
    transition-property:width, background-color;
}
.teamskillbar h6{
    position: absolute;
    top:-25px;
    left:0;
}
.teamskillbar-bar {
    height:10px;
    width:0px;
    background:#ff6863;
    position: absolute;
    left:0px;
    top:0px;
}


.skill_bottom_content{}
.skill_bottom_item .separator_small{
    margin: 20px auto;
}


/*
Pricing Section style
=======================*/
.main_blog,
.main_pricing{
    overflow: hidden;
    width:100%;
}
.pricing_top_border{
    height: 4px;
    width:100%;
    background-color: #ff6863;
}

.pricing_item{
    position: relative;
    box-shadow: 2px 2px 5px rgba(0,0,0,.3);
}
.pricing_head{
    background-color: #f7f7f7;

}
.pricing_price_border{
    background-color: #ff6863;
    padding: 10px;
    border-radius: 50%;
    margin: 0 auto;
    width: 150px;
    height: 150px;
    position: absolute;
    top: 18.6%;
    left: 25%;
    right: 25%;
}
.pricing_price_border .pricing_price{
    background-color: #ff6863;
    border: 2px solid;
    border-color: #fff;
    width: 130px;
    height: 130px;
    border-radius: 50%;
    padding-top: 23%;
}
.pricing_price_border .pricing_price h3{
    margin-bottom: 5px;
    font-size:2.822rem;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
}
.pricing_price_border .pricing_price p{
    font-size:0.929rem;
}
.pricing_body{
    overflow: hidden;
}
.pricing_body ul{
    width:55%;
    margin: 0 auto;
}
.pricing_body ul li{
    line-height: 3rem;
}
.pricing_body ul li i{
    margin-right: 10px;
}


/*
Brand Section style
=====================*/
.main_cbrand{
    overflow: hidden;
    width:100%;
}
.cbrand_item {
    height: 70px;
    overflow: hidden;
}
.cbrand_item img{
    width:70%;
    /*margin: 10px;*/
}
.cbrand_item img.img_apple{
    width:25%;
    padding: 10px;
}


/*
Blog Section style
=====================*/
.blog_item{
    overflow: hidden;
    width:100%;
}
.blog_item_img{
    overflow: hidden;

}
.blog_item_img img{
    width:100%;
    -webkit-transition: all 0.6s;
    -o-transition: all 0.6s;
    -moz-transition: all 0.6s;
    -ms-transition: all 0.6s;
    transition: all 0.6s;
}
.blog_item:hover .blog_item_img img{
    -webkit-transform: scale(1.1) rotate(2deg);
    -moz-transform: scale(1.1) rotate(2deg);
    -o-transform: scale(1.1) rotate(2deg);
    -ms-transform: scale(1.1) rotate(2deg);
    transform: scale(1.1) rotate(2deg);
}
.blog_text{
    border: 1px solid #eee;
}
.blog_text p a{
    color:#666666;
    padding: 5px;
}


/*
Contact Section style
=====================*/

.main_contact{
    overflow: hidden;
}
.main_contact a.btn{
    width:100%;
}

.widget_socail{}
.widget_socail ul li a i{
    background-color: #434343;
    width:40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50%;
    text-align: center;
    color:#fff;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -o-transition: all 0.4s;
    -ms-transition: all 0.4s;
    transition: all 0.4s;
}
.widget_socail ul li a:hover i{
    background-color: #ff6863;
}

.main_contact ul li {
    margin: 10px 0;
}

.main_contact ul img {
    max-width: 40px;
}

/*
Footer Section style
=====================*/

.main_footer{
    overflow: hidden;
}

.main_footer i {
    padding: 0px 5px;
    color: #ff0000;
}



/*maps*/
.main_maps{
    width:100%;
    height: 100%;
}
.main_maps .map_canvas_icon{
    position:absolute;
    left:45%;
    top:35%;
    cursor:pointer;
    /*z-index: 2;*/
}
.main_maps i{
    font-size:2.5rem;
    color:#f38181;

}
.main_maps  h2{
    color:#f38181;
}

.mapheight{
    height: 100px; 
    width: 100%;
}


/* scrollup */

.scrollup{
    width: 30px;
    height: 30px;
    border-radius: 15px;
    opacity: .3;
    position: fixed;
    bottom: 20px;
    right: 25px;
    color: #fff;
    cursor: pointer;
    background-color: #000;
    z-index: 1000;
    transition: opacity .5s, background-color .5s;
    -moz-transition: opacity .5s, background-color .5s;
    -webkit-transition: opacity .5s, background-color .5s;
}

.scrollup:hover {
    background: #ff6863;
    opacity: 1;
}

.scrollup i {
    font-size: 13px;
    position: absolute;
    opacity: 1;
    color: #fff;
    left: 50%;
    top: 50%;
    margin-top: -7px;
    margin-left: -6px;
    text-decoration: none;

}